$dark: #024565;

$primary: lighten($dark, 35%);
$secondary: desaturate(lighten($dark, 25%), 60%);

$body-bg: #fff;
$grey: rgba(0, 0, 0, 0.03);
// $modal-content-color: rgb(30, 30, 30);
$colors: ();
$enable-shadows: true;
$enable-gradients: true;
$yiq-contrasted-threshold: 180;
$enable-rounded: false;
$border-width: 1px;
$input-btn-border-width: 1px;
$gpUpdate: rgb(43, 147, 200);
$webinar: #f5a623;
$diabetes: rgb(144, 187, 97);
$urgentCare: rgb(70, 84, 128);
$womens-health: rgb(62, 143, 141);
$cardiology-and-ecg: rgb(255, 8, 0);
$mens-health: rgb(25, 71, 186);
$palliative-and-end-of-life-care: rgb(129, 199, 132);
$advanced-clinical-practice-update: rgb(154, 99, 233);
$cancer: rgb(191, 26, 48);
$clinic: #a5415f;
$resilience: rgb(127, 42, 70);
$sage: rgb(71, 112, 70);
$grid-gutter-width: 20px;
$link-color: darken(saturate(desaturate(lighten($dark, 25%), 60%), 20%), 10%);
$navbar-dark-color: rgba(255, 255, 255, 0.6);
$min-contrast-ratio: 1.8;
// $dropdown-bg: $dark;

// $dropdown-link-color: rgba(255, 255, 255, 0.8);
// $dropdown-link-hover-color: darken(rgba(255, 255, 255, 0.8), 5%);
// $dropdown-link-hover-bg: lighten($dark, 5%);

// $dropdown-link-active-color: #fff;
// $dropdown-link-active-bg: $primary;

// $dropdown-link-disabled-color: $gray-500;

// $dropdown-item-padding-y: 0.25rem;
// $dropdown-item-padding-x: 1.5rem;

// $dropdown-header-color: $gray-600;
// $dropdown-header-padding: $dropdown-padding-y $dropdown-item-padding-x;

$border-color: desaturate(lighten($dark, 50%), 70%);
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": #198754,
  "info": #0dcaf0,
  "warning": #ffc107,
  "danger": #dc3545,
  "light": #f8f9fa,
  "dark": $dark,
  "darken": rgba(0, 0, 0, 0.25),
  "cancer": $cancer,
  "cardiology-and-ecg": $cardiology-and-ecg,
  "mens-health": $mens-health,
  "palliative-and-end-of-life-care": $palliative-and-end-of-life-care,
  "ccg": #b27007,
  "diabetes": $diabetes,
  "general": rgb(115, 198, 227),
  "hot-topics-gp-update": $gpUpdate,
  "minor-surgery": rgb(97, 173, 211),
  "nurse": rgb(200, 43, 95),
  "resilience": $resilience,
  "regaining-control-in-general-practice": $resilience,
  "gp-trainee": rgb(2, 192, 189),
  "urgent-care": $urgentCare,
  "webinar": $webinar,
  "womens-health": $womens-health,
  "appraisal-essentials": $sage,
  "mental-health": rgb(226, 193, 8),
  "covid": rgb(23, 102, 175),
  "the-green-gp": rgb(44, 76, 59),
  "abnormal-bloods": rgb(102, 0, 0),
  "obesity": rgb(157, 71, 148),
  "nb-organisation": rgb(157, 71, 148),
  "safeguarding": rgba(244, 100, 0),
  "basic-life-support": rgba(100, 0, 244),
  "dermatology": rgb(69, 229, 144),
  "paediatrics": rgb(143, 164, 211),
  "respiratory": rgb(255, 154, 122),
  "new-to-general-practice": rgb(115, 143, 146),
  "nb-plus-grey": rgb(232, 232, 232),
  "grey": $grey,
  "nb-plus-dark-blue": rgb(7, 35, 49),
  "nb-plus-blue": #053f5f,
  "nb-plus-orange": #d89324,
  "clinic": $clinic,
  "no-pharma": #e8ae5d,
  "accent-1": $dark,
  "accent-2": $gpUpdate,
  "accent-3": $webinar,
  "accent-4": $diabetes,
  "accent-5": $clinic,
  "release-date": rgb(71, 112, 70),
  "perk-background": #e2e2e2,
  "gp-horizon": #144ea6,
  "takeout": rgb(237, 136, 41),
);
$font-family-base: "Noto Sans JP", sans-serif;
$font-weight-base: 300;
$font-size-base: 0.9rem;

@import "bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;600&display=swap");

// $fa-font-path: "../webfonts";
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "@fortawesome/fontawesome-free/scss/brands";
@import "@fortawesome/fontawesome-free/scss/regular";
@import "@ng-select/ng-select/themes/default.theme.css";

@import "./styles/book-styles.scss";

@import "highlight.js/styles/vs.css";

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
   * Sidebar
   */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);

  .nav-link {
    font-weight: 500;
    color: #333;
    .feather {
      margin-right: 4px;
      color: #999;
    }
    &.active {
      color: #007bff;
    }
    &:hover .feather,
    &.active .feather {
      color: inherit;
    }
  }

  .sidebar-heading {
    font-size: 0.75rem;
    text-transform: uppercase;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

/*
   * Content
   */

[role="main"] {
  padding-top: 64px; /* Space for fixed navbar */
}

/*
   * Navbar
   */

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
  }
}

/* Draggable classes */
.drag-wrapper {
  padding: 4px 0;
}

.drag-wrapper.dragging {
  border: 1px solid $grey;
  cursor: move;
  background-color: $dark;
  box-shadow: none;
}

/* FORMS */
input.ng-valid:not(.ignore-validity),
textarea.ng-valid:not(.ignore-validity),
select.ng-valid:not(.ignore-validity) {
  border-left: 6px solid $success;
}

input.ng-invalid:not(.ignore-validity),
textarea.ng-invalid:not(.ignore-validity),
select.ng-invalid:not(.ignore-validity) {
  border-left: 6px solid $danger;
}

ckeditor.ng-valid div.ck-editor__editable {
  border-left: 6px solid $success !important;
}
ckeditor.ng-invalid div.ck-editor__editable {
  border-left: 6px solid $danger !important;
}
.ck.ck-balloon-panel {
  z-index: 2000 !important;
}

ngb-timepicker.ng-valid input {
  border-left: 6px solid $success;
}
ngb-timepicker.ng-invalid input {
  border-left: 6px solid $danger;
}
button.calendar,
button.calendar:active {
  width: 2.5rem;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEUSURBVEiJ7ZQxToVAEIY/YCHGxN6XGOIpnpaEsBSeQC9ArZbm9TZ6ADyBNzAhQGGl8Riv4BLAWAgmkpBYkH1b8FWT2WK/zJ8ZJ4qiI6XUI3ANnGKWBnht2/ZBDRK3hgVGNsCd7/ui+JkEIrKtqurLpEWaphd933+IyI3LEIdpCYCiKD6HcuOa/nwOa0ScJEnk0BJg0UTUWJRl6RxCYEzEmomsIlPU3IPW+grIAbquy+q6fluy/28RIBeRMwDXdXMgXLj/B2uimRXpui4D9sBeRLKl+1N+L+t6RwbWrZliTTTr1oxYtzVWiTQAcRxvTX+eJMnlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=") !important;
  background-repeat: no-repeat;
  background-size: 1.5rem;
  background-position: center;
}

.btn-excel {
  @include button-variant(rgb(0, 105, 60), darken(rgb(0, 105, 60), 5%));
}

.btn-pdf {
  @include button-variant(rgb(255, 0, 0), darken(rgb(255, 0, 0), 5%));
}

/* Below needed for font-awesome to display icons through css */
.icon::before {
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.btn-excel::before {
  font: var(--fa-font-solid);
  content: "\f1c3";
  padding-right: 0.25rem;
}

.btn-pdf::before {
  font: var(--fa-font-solid);
  content: "\f1c1";
  padding-right: 0.25rem;
}

// /* Importing Bootstrap SCSS file. */
// @import "bootstrap/scss/bootstrap";

.list-group-lettered {
  list-style-type: none;
  counter-reset: section;

  > .list-group-item::before {
    // Increments only this instance of the section counter
    content: counter(section, upper-alpha) ". "; /* Use 'upper-alpha' for A, B, C or 'lower-alpha' for a, b, c */
    counter-increment: section;
  }
}
